import image1 from "../assets/t-image1.png";
import image2 from "../assets/t-image2.jpg";
import image3 from "../assets/t-image3.jpg";

export const testimonialsData = [
  {
    image: image1,
    review:
      "I made the right choice by choosing the Fitclub and by choosing the right plan and program I already achieved my ideal body!",
    name: "Shiv Pandey",
    status: "Customer",
  },
  {
    image: image2,
    review:
      "In this fitness club we provide every exercise with an expert trainer",
    name: "Mukehs Kumar",
    status: "COACH",
  },
  {
    image: image3,
    review:
      " this fitness club is really cool and trainer are very expert and they trined you every exercise",
    name: "Raj Kumar",
    status: "CUSTOMER",
  },
];
